// @flow
import React from 'react'

import DefaultLayout from '../components/DefaultLayout'
import DownloadBadges from '../components/DownloadBadges'
import BlogLinkStrip from '../blocks/BlogLinkStrip'
import IntroStrip from '../blocks/IntroStrip'
import {Col, Row} from '../ui/layout'

const Success = () => (
  <DefaultLayout
    title="🎉 Congratulations! 🎉"
    description="Your friends are amazing and you have received some Superdays"
  >
    <IntroStrip bottomWave="curve" headline="🎉 Congratulations! 🎉">
      <Col align="center" spacing={2}>
        <Row>
          Your friends are amazing and you have received some Superdays
          <br />
          Download the app to retrieve your personal calendar.
        </Row>
        <DownloadBadges />
      </Col>
    </IntroStrip>
    <BlogLinkStrip headline="What do you get again?" />
  </DefaultLayout>
)

export default Success
